import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "./index.scss";
import Drawer from "@mui/material/Drawer";
import rapid from "../../resources/icons/rapid-logo.png";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WifiIcon from "@mui/icons-material/Wifi";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import UpdateIcon from "../../resources/icons/rapid-updates.png";
import DeviceControlIcon from "../../resources/icons/rapid-device-control.png";
import Devices from "../../resources/icons/rapid-devices.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { checkPermission } from "../api/index";
import { Button } from "@mui/material";
import UserGuidePdf from "../../resources/images/RapidUserGuide.pdf";
import InfoIcon from '@mui/icons-material/Info';

const SideMenu = (props) => {
  const history = useNavigate();
  const [activeMenu, setActiveMenu] = useState(1);
  const [menuName, setMenuName] = useState("");
  const [isAllowed, setIsAllowed] = useState(false);
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [openflag, setOpenFlag] = useState(true);
  const user = JSON.parse(sessionStorage.getItem("user"))?.user;
  const limits = JSON.parse(sessionStorage.getItem("user"))?.limits;
  const Menu = [
    {
      menu: "home",
      subMenu: [
        {
          name: "Dashboard",
          icon: <DashboardIcon />,
          id: 1,
          path: "/dashboard",
        },
        {
          name: "Devices",
          icon: (
            <img
              src={Devices}
              alt="Remote Accesss"
              style={{
                width: "22px",
                height: "28px",
                position: "relative",
                top: "-2px",
                left: "2px",
              }}
            />
          ),
          id: 2,
          path: "/devices",
        },
        isAllowed && {
          name: "Monitor",
          icon: <AssessmentIcon />,
          id: 3,
          path: "/monitor",
          array: [
            {
              name: "System Status",
              id: 31,
              path: "/system-status",
            },
            {
              name: "Kernel Logs",
              id: 32,
              path: "/kernel-logs",
            },
            {
              name: "Processes",
              id: 33,
              path: "/processes",
            },
            {
              name: "Real Time Graphs",
              id: 34,
              path: "/real-time-graphs",
            },
            // {
            //   name: "Alert",
            //   id: 35,
            //   path: "/alert",
            // },
          ],
        },
        isShow &&
        {
          // name: "Control",
          // icon: (
          //   <img
          //     src={DeviceControlIcon}
          //     alt="Devices"
          //     style={{
          //       width: "20px",
          //       height: "20px",
          //       position: "relative",
          //       top: "-0.5px",
          //       left: "2px",
          //     }}
          //   />
          // ),
          // id: 4,
          // path: "/device-control",
          // array: [
          //   // {
          //   //   name: "Remote Access",
          //   //   id: 41,
          //   //   path: "/rempte-access",
          //   // },
          //   {
          //     name: "Command Execution",
          //     id: 42,
          //     path: "/command-execution",
          //   },
          // ],
        },
        // {
        //   name: "Remote Access",
        //   icon:
        //   id: 4,
        //   path: ,
        // },
        {
          name: "OTA Updates",
          icon: (
            <img
              src={UpdateIcon}
              alt="Updates"
              style={{
                width: "25px",
                height: "20px",
                position: "relative",
                top: "3px",
              }}
            />
          ),
          id: 5,
          path: "/rollout",
          // array: [
          //   {
          //     name: "OTA Update",
          //     id: 51,
          //     path: "/rollout",
          //   },
          //   // {
          //   //   name: "Distributions",
          //   //   id: 52,
          //   //   path: "/distributions",
          //   // },
          // ],
        },
        {
          name: "Settings",
          icon: <SettingsIcon />,
          id: 6,
          path: "/setting?tab=account",
        },
        {
          name: "User Guide",
          icon: <InfoIcon />,
          id: 7,
          path: UserGuidePdf,
        }
      ],
    },
  ];

  const handleClick = (path, id, name) => {
    if (path.endsWith(".pdf")) {
      window.open(path, "_blank");
    } else {
      // if (id == activeMenu) {
      //   // setActiveMenu(null);
      //   // setMenuName(null);
      //   setOpenFlag(!openflag);
      // } else {
      setActiveMenu(id);
      setMenuName(name);
      history(path);
      // }
    }
  };


  useEffect(() => {
    let allowed = checkPermission(
      "ALLOW_MONITOR_DEVICE",
      user?.isAdmin,
      limits?.allowMonitorDevice
    );
    setIsAllowed(allowed);
  }, []);

  // useEffect(() => {
  //   if (menuName) {
  //        props.headerName(menuName);
  //   }
  // }, [activeMenu]);

  useEffect(() => {
    if (props.activeMenu) {
      let menuString = props.activeMenu?.toString();
      if (menuString.length > 1) {
        // let opList = null;
        // if (!opList.includes(parseInt(menuString.charAt(0)))) {
        //   opList.push(parseInt(menuString.charAt(0)));
        // }
        // opList = parseInt(menuString.charAt(0));
        // setOpen(opList);
        let subMenu = Menu[0].subMenu[
          parseInt(menuString.charAt(0)) - 1
        ].array.filter((e) => e.id == props.activeMenu);
        setMenuName(subMenu[0].name);
      }
      setActiveMenu(props.activeMenu);
    }
  }, [props.activeMenu]);

  const openFun = (id, name) => {
    setOpenFlag(true);
    if (id == activeMenu || activeMenu?.toString().charAt(0) == id) {
      // setActiveMenu(null);
      // setMenuName(null);
      setOpenFlag(!openflag);
    } else {
      setActiveMenu(id);
      setMenuName(null);
    }
    // let opList = id;
    // console.log(id);
    // if (opList.includes(id)) {
    //   opList.splice(opList.indexOf(id), 1);
    // } else {
    //   opList.push(id);
    // }
    //  setOpen(opList);
  };
  const menuFun = (menu) => {
    let arrayList = [];
    menu.subMenu.forEach((list) => {
      if (list) {
        arrayList.push(
          <ListItem
            key={list.name}
            button
            onClick={() =>
              list.array
                ? openFun(list.id, list.name)
                : handleClick(list.path, list.id, list.name)
            }
            style={{
              backgroundColor:
                list.id == activeMenu ||
                  activeMenu?.toString().charAt(0) == list.id
                  ? "#055f85"
                  : "",
              borderRadius: "15px",
              paddingLeft: "50px",
              boxShadow:
                activeMenu?.toString().charAt(0) == list.id
                  ? "0px 7px 7px -3px #333637"
                  : "",
              margin: "5px 0px 5px 0px",
            }}
            selected={list.id == activeMenu}
          >
            <div
              style={{
                backgroundColor:
                  list.id == activeMenu ||
                    activeMenu?.toString().charAt(0) == list.id
                    ? "#0fcbd0"
                    : "",
                width: "25px",
                height: "25px",
                margin: "5px",
                padding: "11px",
                position: "absolute",
                top: "-4.5px",
                left: "-6px",
                borderRadius: "15px",
              }}
            >
              <ListItemIcon
                style={{
                  color: "white",
                }}
              >
                {list.icon}
              </ListItemIcon>
            </div>
            {true && (
              <>
                <ListItemText
                  style={{
                    color: "#fff",
                    marginLeft:
                      list.id == activeMenu ||
                        activeMenu?.toString().charAt(0) == list.id
                        ? "10px"
                        : "0px",
                  }}
                  primary={list.name}
                />
                {list.array ? (
                  (activeMenu == list.id ||
                    activeMenu?.toString().charAt(0) == list.id) &&
                    openflag ? (
                    <KeyboardArrowUpIcon
                      style={{ fontSize: "18px", color: "white" }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      style={{ fontSize: "18px", color: "white" }}
                    />
                  )
                ) : (
                  ""
                )}
              </>
            )}
          </ListItem>
        );
        let temp = [];
        list.array?.forEach((inner) => {
          temp.push(
            <ListItem
              key={inner.name}
              button
              onClick={() => handleClick(inner.path, inner.id, inner.name)}
              selected={inner.id == activeMenu}
              style={{
                borderRadius: "20px",
                backgroundColor: inner.id == activeMenu ? "#0fcbd0" : "",
                display:
                  activeMenu == list.id ||
                    activeMenu?.toString().charAt(0) == list.id
                    ? ""
                    : "none",
                marginLeft: "12px",
                position: "relative",
                top: "5px",
                marginRight: "12px",
                width: "auto",
              }}
            >
              {true && (
                <ListItemText>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "13px",
                    }}
                  >
                    {inner.name}
                  </div>
                </ListItemText>
              )}
            </ListItem>
          );
        });
        if (list.array?.length > 0)
          arrayList.push(
            <div
              style={{
                backgroundColor: "#085e6f",
                paddingBottom: "10px",
                display:
                  (activeMenu == list.id ||
                    activeMenu?.toString().charAt(0) == list.id) &&
                    openflag
                    ? ""
                    : "none",
                borderRadius: "0px 0px 10px 10px",
                paddingTop: "20px",
                marginTop: "-15px",
              }}
            >
              {temp}
            </div>
          );
      }
    });
    return arrayList;
  };

  return (
    <>
      <div className="sideBar tr-hide-mobile">
        {Menu.map((menu) => {
          return (
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              key={"menu" + menu.id}
              style={{ margin: "10px" }}
              subheader={
                false ? (
                  <ListSubheader
                    style={{ color: "gray" }}
                    component="div"
                    id="nested-list-subheader"
                  >
                    {menu.name}
                  </ListSubheader>
                ) : null
              }
            >
              {menuFun(menu)}
            </List>
          );

        })}
      </div>
      <div className=" tr-hide-web">
        <IconButton
          size="large"
          onClick={() => setOpen(true)}
          aria-label="logo"
        >
          <MenuIcon className="drawer-icon-btn" fontSize="large"></MenuIcon>
        </IconButton>
        <Drawer
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          className="sideBar tr-hide-web"
        >
          <div
            style={{
              backgroundColor: "#044966",
              height: "100vh",
            }}
          >
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={rapid}
                alt="Rapid"
                style={{ width: "150px", height: "50px" }}
              />
            </div>
            {Menu.map((menu) => {
              return (
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  key={"menu" + menu.id}
                  style={{ margin: "10px" }}
                  subheader={
                    false ? (
                      <ListSubheader
                        style={{ color: "gray" }}
                        component="div"
                        id="nested-list-subheader"
                      >
                        {menu.name}
                      </ListSubheader>
                    ) : null
                  }
                >
                  {menuFun(menu)}
                </List>
              );
            })}
          </div>
        </Drawer>
      </div>
    </>
  );
};
export default SideMenu;
