import React from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

const DropZone = (props) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section
      className="container"
      style={{
        backgroundColor: "white",
        padding: "10px",
        width: "95%",
        borderRadius: "5px",
      }}
    >
      <div style={{ border: "1px dashed gray", height: "39vh" }}>
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{ marginTop: "20%" }}
        >
          <input {...getInputProps()} />
          <p>
            <center>
              <CloudUploadOutlinedIcon
                style={{ fontSize: "75px", color: "#0fcbd0" }}
              />
            </center>
            <center>{"Drag & Drop Files Here"}</center>
          </p>
          <p>
            <center>
              <span
                style={{
                  border: "1px solid green",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                Upload Files
              </span>
            </center>
          </p>
        </div>
        <aside>
          <ul>{files}</ul>
        </aside>
      </div>
    </section>
  );
};
export default DropZone;
